<template>
  <div>
    <div class="mb-10 flex flex-col">
      <div class="flex justify-between items-center mb-3">
        <span class="cursor-pointer text-13 font-bold">
          {{ profile.business_name | sentenceCase }} Access
        </span>
        <div class="inline-flex items-center">
          <button
            class="btn btn-red-outline btn-sm mr-3"
            :disabled="selectedAccessIsEmpty"
          >
            Cancel
          </button>
          <button class="btn btn-blue btn-sm" @click="updateVendorAccess">
            Save changes
          </button>
        </div>
      </div>
      <div class="grid grid-cols-4 gap-3 mt-2">
        <button
          class="btn btn-green-outline btn-sm"
          v-for="(access, i) in selectedAccess"
          :key="i"
          @click="removeAccess(access)"
        >
          {{ access.name }}
        </button>
      </div>
    </div>

    <div class="mb-5 flex flex-col">
      <span class="cursor-pointer text-13 font-bold mb-2">
        All Access (Click to choose access)
      </span>
      <sm-loader v-if="vendorAccess.loading" />
      <div v-else class="grid grid-cols-4 gap-3 mt-2">
        <button
          class="btn btn-blue-outline btn-sm"
          v-for="(access, i) in vendorAccess.data"
          :key="i"
          @click="addAccess(access)"
        >
          {{ access.name }}
        </button>
      </div>
    </div>

    <preference v-if="profile" />
  </div>
</template>

<script>
export default {
  props: {
    profile: {
      required: true,
      type: [Array, Object]
    }
  },
  components: {
    Preference: () => import('../components/Preference.vue')
  },
  data() {
    return {
      vendorAccess: this.$options.resource([]),
      selectedAccess: []
    };
  },
  computed: {
    selectedAccessIsEmpty() {
      return !!this.selectedAccess?.length === 0;
    },
    merchantConfig() {
      return this.profile?.configuration;
    },
    maxOrderAmount() {
      return this.merchantConfig?.max_order_amount;
    }
  },
  // watch: {
  //   'vendorAccess.data'() {
  //     console.log(this.vendorAccess.data);
  //     console.log(
  //       this.vendorAccess?.data?.filter(
  //         access => !this.selectedAccess.includes(access)
  //       )
  //     );
  //   }
  // },
  beforeMount() {
    this.allVendorAccess();
  },
  mounted() {
    this.selectedAccess = this.profile?.vendorAccess;
  },
  methods: {
    clearFormData(form) {
      for (let key in form.data) {
        form.data[key].value = '';
      }
    },
    isAccessSelected(access) {
      return !!this.selectedAccess?.find(a => a.slug === access.slug);
    },
    addAccess(data) {
      if (
        this.selectedAccess.findIndex(access => access.slug === data.slug) ===
        -1
      ) {
        this.selectedAccess?.push(data);
      }
      this.vendorAccess.data = this.vendorAccess.data?.filter(
        access => access !== data
      );
    },
    removeAccess(data) {
      if (
        this.vendorAccess?.data?.findIndex(
          access => access.slug === data.slug
        ) === -1
      ) {
        this.vendorAccess?.data?.push(data);
      }
      this.selectedAccess = this.selectedAccess.filter(
        access => access !== data
      );
    },
    async updateVendorAccess() {
      await this.sendRequest('admin.merchants.storeVendorAccess', {
        data: {
          merchant_id: this.profile?.id,
          selected: this.selectedAccess?.map(access => access?.id)
        },
        success: () => this.$emit('success'),
        error: error => console.log(error)
      });
    },
    async allVendorAccess() {
      this.vendorAccess.loading = true;
      await this.sendRequest('admin.merchants.vendorAccess', {
        success: ({ data: { data } }) => {
          this.vendorAccess.data = data;
        },
        error: error => console.log(error)
      });
      this.vendorAccess.loading = false;
    }
  }
};
</script>
